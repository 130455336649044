import React from "react";
import { graphql, Link } from "gatsby";

import Fade from "react-reveal/Fade";

import Layout from "../components/Layout";
import SEO from "../components/seo";

class LookbookTemplate extends React.Component {
  render() {
    const post = this.props.data.mdx;
    const { frontmatter } = post;
    const siteTitle = this.props.data.site.siteMetadata.title;

    let iframeURL = "";
    if (frontmatter.embed_code) {
      iframeURL = frontmatter.embed_code.slice(
        frontmatter.embed_code.indexOf('src="') + 'src="'.length
      );

      iframeURL = iframeURL.slice(0, iframeURL.indexOf('"'));
    }

    return (
      <Layout
        location={this.props.location}
        title={siteTitle}
        HeaderExtension={
          <div className="header_extension no_bg lookbook">
            <div className="bg">
              <div className="container aligncenter">
                <div className="columns post-single ui-grid">
                  <div className="column is-8">
                    <Fade bottom duration={1000} delay={125} distance={"25%"}>
                      <h1 className="primary heading_lg aligncenter">
                        <span>{frontmatter.title}</span>
                      </h1>
                    </Fade>

                    {frontmatter.action_text && (
                      <Fade bottom duration={1000} delay={125} distance={"25%"}>
                        {frontmatter.action_url_isExternal ? (
                          <a
                            className="button"
                            href={frontmatter.action_url}
                            target="_blank"
                            rel="nofollow"
                          >
                            {frontmatter.action_text}
                          </a>
                        ) : (
                          <Link className="button" to={frontmatter.action_url}>
                            {frontmatter.action_text}
                          </Link>
                        )}
                      </Fade>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      >
        <SEO
          title={frontmatter.title}
          image={null}
          description={frontmatter.description}
        />

        <div className="brand-single">
          {iframeURL && (
            <div className="container">
              <div
                style={{
                  position: "relative",
                  paddingTop: "max(60%,326px)",
                  height: 0,
                  width: "100%",
                }}
              >
                <iframe
                  sandbox="allow-top-navigation allow-top-navigation-by-user-activation allow-downloads allow-scripts allow-same-origin allow-popups allow-modals allow-popups-to-escape-sandbox"
                  allowfullscreen="true"
                  style={{
                    position: "absolute",
                    border: "none",
                    width: "100%",
                    height: "100%",
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0,
                  }}
                  src={iframeURL}
                ></iframe>
              </div>
            </div>
          )}

          {!iframeURL && (
            <div className="aligncenter">
              <b>Embed code is either invalid or missing.</b>
            </div>
          )}
        </div>
      </Layout>
    );
  }
}

export default LookbookTemplate;

export const pageQuery = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      fields {
        slug
      }
      id
      excerpt(pruneLength: 160)
      frontmatter {
        title
        subtitle
        action_text
        action_url
        action_url_isExternal
        embed_code
      }
      body
    }
  }
`;
